@import "~bootstrap/scss/bootstrap";
@import "colors";
@import "fonts";

/** 
* Utils
*/
.color-primary {
  color: $primary;
}

/**
* App
*/
* {
  font-family: "Josefin Sans", sans-serif;
}
body {
  height: 100vh;
  background: #EDF1F5;
}
#root,
.App {
  width: 100%;
  overflow: hidden;
}
header {
  height: 100px;
  overflow: hidden;
}
main {
  height: calc(100% - 100px);
  padding: 0 15px;
}
a {
  color: $tertiary;
}
a:hover, a:active {
  color: $tertiary;
  opacity: 0.8;
  text-decoration: none;
}
.progressive-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.spinner {
  position: absolute;
  height: 100px;
  width: 170px;
  top: calc(50% - 85px);
  left: calc(50% - 85px);
  i {
    width: 47px;
    height: 47px;
    margin: 0 auto;
    display: block;
    background-image: url("../assets/img/spinner.gif") !important;
    background-size: contain !important;
/*     animation-name: spin;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; */
    /* transform: rotate(3deg); */
    /* transform: rotate(0.3rad);/ */
    /* transform: rotate(3grad); */
    /* transform: rotate(.03turn);  */
  }
  p {
    margin-top: 15px;
    text-align: center;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
.MuiCard-root {
  box-shadow: none !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $primary !important;
}
.MuiFormLabel-root.Mui-focused {
  color: $primary !important;
}

/**
* Button
*/
.btn {
/*   width: calc(100% - 5px);
  margin: 0 3px; */
  width: 100%;
  margin: 0;
  padding: 0;
  button {
    text-align: center;
    width: 100%;
    color: $blank;
    background: $tertiary;
    .MuiButton-label {
      margin-top: 5px;
      text-transform: none;
    }
  }
  button:hover {
    color: $blank;
    background: $tertiary;
    opacity: 0.8;
  }
}
.MuiButton-root.Mui-disabled {
  opacity: 0.3;
  color: $blank !important;
}

/**
* Banner
*/
.banner {
  height: 100px;
  overflow: hidden;
  background: #21011c;
  background: linear-gradient(213deg, #24011f 0, #3f0135 35%, #21011c 78%);
  .back-button {
    position: absolute;
    z-index: 99;
    left: 15px;
    top: 32px;
    color: white;
  }
  img {
    width: 100%;
  }
  img.bg-overlay {
    filter: brightness(50%);
  }
  h3 {
    position: absolute;
    top: 30px;
    font-size: 1.6em;
    display: block;
    width: 90%;
    margin: 8px 5% 0 5%;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    color: #FFF;
  }
  .logo {
    position: absolute;
    margin: 10px calc(50% - 72px);
    width: auto;
    height: 80px;
    z-index: 99;
  }
  &.icons-invert {
    a img {
      filter: invert(1) !important;
    }
  }
}

/**
* Featured Menu
*/
.featured {
  margin: 0 -5px;
  .btn {
    width: calc(100% - 5px) !important;
    margin: 0 3px !important;
  }
  .featured-title {
    display: flex;
    span {
      font-size: 1.1rem;
      padding: 5px 0 5px 5px;
      flex: 1;
    }
    a {
      color: #a9a9a9;
      font-size: 0.8rem;
      text-decoration: none;
      padding-top: 8px;
    }
  }
  .featured-img {
    img {
      padding: 0;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  .MuiCardMedia-root {
    width: 100%;
    height: 124px;
    background-size: auto 170px;
    padding: 0;
    -webkit-filter: brightness(50%);
    filter: brightness(50%);
  }
  .title {
    display: block;
    //font-weight: bold;
    position: absolute;
    top: calc(50% - 9px);
    width: calc(100% - 0.5rem);
    text-align: center;
    color: white;
    //font-family: "Lights of the Stardust", sans-serif !important;
  }
}

/**
* Suggestions
*/
.suggestions {
  height: auto;
  //background-color: $tertiary;
  .suggestions-title {
    display: flex;
    span {
      font-size: 1.1rem;
      padding: 10px 0 0 0;
      flex: 1;
    }
    a {
      color: $secondary;
      font-size: 0.8rem;
      text-decoration: none;
      padding-top: 12px;
    }
  }
  .MuiCardMedia-root {
    width: 100%;
    height: 250px;
    background-size: cover;
    padding: 0;
  }
  .react-multi-carousel-list {
    padding: 5px 0;
    overflow: visible !important;
  }
  .react-multi-carousel-item {
    .MuiCardContent-root:last-child {
      padding-bottom: 15px;
      position: absolute;
      width: 95%;
      display: block;
      height: 170px;
      margin-top: -170px;
      background: url('../assets/img/bg-degrade.png') repeat-x;
      border-radius: 5px;
      p {
        position: absolute;
        left: 15px;
        right: 15px;
        bottom: 15px;
      }
    }
  }
  .title {
    display: block;
    color: $blank;
    //font-weight: bold;
    font-size: 1.1rem;
  }
  .description {
    color: $blank;
  }
  .price {
    display: block;
    color: $blank;
    font-size: 1.1rem;
  }
}

/**
* Menu card
*/
.show-page {
  .menu-card {
    //padding-bottom: 0.5rem;
    //border-radius: 0;
    border: 0;
    //border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    border-radius: 5px;
    .MuiCardMedia-root {
      height: 96px;
      width: 100%;
      img {
        border-radius: 5px;
      }
    }
    .card-body {
      padding: 0 0.5rem;
      .card-title {
        margin-bottom: 0;
        color: $primary;
        //font-weight: bold;
        font-size: 1.1rem;
      }
      .card-text.description {
        max-height: 2rem;
        line-height: 1rem;
        overflow: hidden;
        margin: 5px 0 10px 0;
      }
      .card-text.price {
        line-height: 1.2rem;
        color: $secondary;
        font-size: 1.1rem;
      }
    }
  }
}

/*
* Menu Modal
*/
.MuiDialog-root {
  header {
    height: auto !important;
    background: white !important;
  }
}
.menu-modal {
  .MuiCardMedia-root {
    max-height: 200px;
    height: 200px;
    width: 100%;
  }
  .MuiPaper-root {
    //background-image: url("../assets/img/logo-gray.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 70%;
    background-size: 100%;
    position: fixed;
  }
  .modal-close {
    background: #00000091;
    position: absolute;
    left: 20px;
    top: 10px;
    color: white;
  }
  .title {
    display: block;
    color: $primary;
    //font-weight: bold;
    font-size: 1.2rem;
  }
  .description {
    max-height: 2rem;
    line-height: 1rem;
    overflow: hidden;
    margin: 5px 0 10px 0;
  }
  .price {
    float: right;
    display: block;
    color: $secondary;
    font-size: 1.5rem;
  }
  .cant {
    display: inline-block;
    min-width: 50%;
  }
  .variant {
    min-width: 50%;
  }
}

/*
* Cart shop
*/
.shop-cart-widget {
  position: absolute;
  z-index: 99;
  right: 15px;
  top: 32px;
  color: white;
  img {
    width: 34px;
    height: 34px;
  }
}
.shop-count-button {
  display: block;
  span {
    padding: 5px 10px;
    vertical-align: middle;
    font-size: 0.9rem;
    font-weight: normal;
    color: #111;
  }
  button {
    border: 1px solid #cfcfcf;
    min-width: auto;
    padding: 14px;
    img {
      width: 10px;
    }
    span {
      padding: 0;
    }
  }
}
.shop-select-variant {
  .MuiOutlinedInput-input {
    padding: 10.5px 32px 10.5px 14px !important;
  }
}
.shop-add-button {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 10px 15px 15px 15px;
  background: #fff;
  .shop-cant {
    position: absolute;
    left: 10px;
    top: 10px;
    img {
      width: 24px;
      height: 24px;
    }
    .MuiBadge-badge {
      //background: $tertiary;
      //border: 1px solid #fff;
      left: 4px;
      top: 3px;
    }
  }
  .shop-total {
    position: absolute;
    right: 10px;
  }
  .box-details {
    max-height: 200px;
    overflow: auto;
  }
  .item-details {
    margin: 0px 0 5px 0;
    padding: 2px 1rem;
    border-bottom: 1px solid #d6d6d6;
    font-size: 0.9rem;
    color: #a5a5a5;
  }
}
.cart {
  min-height: 100vh;
  background: #ffffff;
  main {
    padding: 0 15px 100px 15px;
  }
  .empty-cart {
    text-align: center;
    margin-top: 30%;
    color: #a9a9a9;
  }
  .cart-item {
    height: 91px;
    background: #EDF1F5;
    padding: 0.5rem;
    border-radius: 5px;
    margin-bottom: 10px;
    .cart-image {
      width: 75px;
      height: 75px;
      display: inline-block;
      border-radius: 5px;
      background-size: cover;
      background-position: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
      }
    }
    .cart-title {
      position: absolute;
      margin-left: 10px;
      color: $primary;
      //font-weight: bold;
      font-size: 1.1rem;
      padding-right: 40px;
      line-height: 1.4rem;
    }
    .cart-price {
      float: right;
      margin-top: 54px;
      color: $primary;
      //font-weight: bold;
      font-size: 1.1rem;
    }
    .cart-cant-item {
      position: absolute;
      margin-top: -25px;
      margin-left: 85px;
      .MuiButton-text {
        padding: 6px 8px !important;
      }
    }
    .cart-delete-item {
      position: absolute;
      right: 23px;
      margin-top: -76px;
      button {
        border: 1px solid #cfcfcf;
        min-width: auto;
        img {
          width: 10px;
        }
        span {
          padding: 0;
        }
      }
    }
  }
}

//PAGES
.menu {
  .MuiCard-root {
    height: 20vh;
    margin: 14px 0;
    overflow: hidden;
    .title {
      position: absolute;
      color: white;
      font-size: 1.4rem;
      display: inline-block;
      margin-top: calc(-10vh - 0.8rem);
      margin-left: 0;
      width: calc(100% - 30px);
      text-align: center;
    }
  }
  .MuiCardMedia-root {
    overflow: hidden;
    background-size: cover;
    height: 20vh;
    width: 100%;
    padding: 0;
    margin: 0;
    filter: brightness(50%);
  }
}

//SHOW
.show-page {
  header {
    height: 100px;
    width: 100%;
    overflow: hidden;
    position: fixed;
    z-index: 99;
    top: 0;
  }
  main.show-page-container {
    z-index: 98;
    padding: 15px;
    overflow: auto;
    margin-top: 100px;
    div:last-child {
      border-bottom: 0 !important;
    }
  }
}

//CONFIRM
.confirm {
  min-height: 100vh;
  background: #ffffff;
  .order-address {
    width: calc(100% + 30px);
    height: auto;
    display: block;
    margin: 0px -15px 15px -15px;
    border-width: 0 0 1px 0;
    padding: 15px;
    border-color: #cacaca;
    border-style: solid;
    .left-col {
      img {
        margin-right: 10px;
        margin-top: -25%;
        transform: translate(0, 50%);
      }
    }
    .middle-col {
      small {
        display: block;
        color: #a5a5a5;
        font-size: 0.9rem;
      }
    }
    .right-col {
      span {
        height: 100%;
        display: block;
        margin-top: -25%;
        transform: translate(0, 50%);
      }
    }
  }
  .order-info {
    border: 1px solid #cacaca;
    padding: 1rem;
    border-radius: 5px;
    text-align: center;
    h3 {
      margin: 0;
      color: #cacaca;
    }
  }
}
.order-shipping {
  min-width: 320px !important;
  margin-bottom: 10px !important;
  .MuiIconButton-label {
    color: $secondary;
  }
  label {
    margin: 0;
    span {
      padding: 2px;
    }
  }
}
.order-shipping-modal {
  .footer-btn {
    width: 100%;
    margin: 0;
    padding: 10px 15px 15px 15px;
    background: #fff;
  }
}
